.active-submission-wrapper {
  background-color: white;
  padding: 0.625rem 0 0.375rem 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 45px;
    padding: 0 12px;

    @media screen and (max-width: $breakpoint-vertical-active-submission-info) {
      align-items: flex-start;
    }

    @media screen and (max-width: $breakpoint-mobile-nav) {
      display: block;
      padding-bottom: 12px;
    }
  }

  .container.full-width {
    max-width: unset;
  }

  .company-name {
    color: $color-black;
    font-size: 32px;
    font-weight: bold;
    margin-right: 16px;

    @media screen and (max-width: $breakpoint-mobile-nav) {
      margin-right: 0;
    }
  }

  .info {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;

    @media screen and (max-width: $breakpoint-vertical-active-submission-info) {
      display: block;
    }

    .padding {
      padding: 4px 12px;
    }

    .has-icon {
      padding-left: 8px;
    }

    .item {
      color: $color-black;
      background: $color-orange-light;
      display: flex;
      align-items: center;
      border-radius: 15px;
      margin-right: 8px;

      @media screen and (max-width: $breakpoint-vertical-active-submission-info) {
        margin: 8px 0 8px 0;
      }

      @media screen and (max-width: $breakpoint-mobile-nav) {
        margin: 8px 0 0 0;
      }

      .label {
        font-size: 14px;
        display: block;
        margin-right: 4px;
        margin-bottom: 0;
      }

      .value {
        font-size: 14px;
        display: block;
        color: $color-grey-dark;
        font-weight: 600;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .menu {
      .toggle {
        color: $color-marmalade;
        width: 10px;
      }
    }
  }
}
