* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: $color-grey-very-light;
  overflow-y: scroll;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.centered {
  align-self: center;
  text-align: center;
}

.container {
  max-width: $max-content-width;
  margin: 0 auto;
}

.has-actions {
  background: transparent;

  .actions {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    background: #edf0f3;
    border-radius: 0.375rem;
    transition: 150ms;

    .actions {
      opacity: 1;
      pointer-events: inherit;
      transition: 100ms;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.pulse {
  animation: pulse 2s ease-in-out 0.5s infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in {
  animation: fadeIn 120ms ease-in;
}
.fade-in-slow {
  animation: fadeIn 300ms ease-in;
}

@keyframes delayReveal {
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
  }
}
.delay-reveal {
  animation: delayReveal 4000ms ease-in-out;
}

.hidden-desktop {
  display: none;

  @media screen and (max-width: $breakpoint-mobile-nav) {
    display: unset;
  }
}

@media screen and (max-width: $breakpoint-mobile-nav) {
  .hidden-mobile {
    display: none;
  }
}
