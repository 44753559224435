.tile-options {
  color: $color-grey-dark;
  direction: "column";
  text-transform: none;
  text-align: left;

  &.h3 {
    color: $color-black;
    padding-right: 30px;

    &.selected {
      padding-right: 10px;
      color: $color-marmalade;
    }
  }

  &.button {
    border-color: $color-grey;
    background-color: $color-white;

    &.selected {
      border-color: $color-marmalade;
    }
  }
}
