.status-badge {
  $grey: #ededec;
  $dark-grey: gainsboro;
  $gold: gold;
  $blue: dodgerblue;
  $purple: mediumslateblue;
  $green: mediumseagreen;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 1.625rem;
  padding: 0 0.75rem 0 0.375rem;
  line-height: 0.8125rem;
  font-size: 0.8125rem;
  border-radius: 0.875rem;

  svg {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.25rem;
  }

  &.grey {
    background: $grey;
    color: #363839;
  }

  &.gold {
    background: $gold;
  }

  &.blue {
    background: $blue;
    color: white;
  }

  &.purple {
    background: $purple;
    color: white;
  }

  &.green {
    background: $green;
    color: white;
  }

  &.darkgrey {
    background: $dark-grey;

    svg {
      fill: $dark-grey;
    }
  }

  &.mini {
    width: 0.9375rem;
    height: 0.9375rem;
    padding: 0;

    svg {
      width: 0.875rem;
      height: 0.875rem;
      margin-right: 0;
    }

    &.grey {
      background: $dark-grey;
    }
  }

  &.large {
    width: 1.125rem;
    height: 1.125rem;
  }

  &.dot {
    width: 7px;
    height: 7px;
    padding: 0;
    border-radius: 4px;
    border: 1px solid white;
  }
}

.MuiChip-root {
  .mini {
    width: 1.125rem;
    height: 1.125rem;
    margin: 0 -4px 0 2px;
  }
}
