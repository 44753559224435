h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  font-family: $font !important;
}

h1 {
  color: $color-black;
  font-size: 36px;
  font-weight: 500;
}

.monospace {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;

  input {
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
      monospace;
  }
}

.label {
  color: $color-grey;
  font-weight: 600;
  font-size: 13px;
  display: block;
  margin-bottom: 4px;
}
