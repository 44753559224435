.rate-change-summary {
  .Mui-disabled {
    background-color: transparent !important;
    color: black !important;
  }
  input {
    color: black !important;
  }
  .MuiInputBase-input:disabled {
    color: black;
    -webkit-text-fill-color: black;
    text-align: right;
  }
}
