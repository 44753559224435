.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 36px;
  border-width: 3px;
  border-radius: 12px;
  border-color: #7c809d3b;
  border-style: dashed;
  background-color: #fff;
  color: #bdbdbd;
  outline: none;
  cursor: pointer;
  transition: 250ms;

  .message {
    color: $color-grey;
    font-size: 0.9rem;
    text-align: center;
  }

  &:hover {
    color: #a2a2a2;
    background-color: #fcfcfc;
  }

  &.accept {
    border-color: $color-status-green;
  }

  &.reject {
    border-color: $color-status-red;
  }

  &.active {
    border-color: $color-status-green;
  }
}
