.submission-summary {
  color: $color-black;
  padding: 10px;
  white-space: break-spaces;

  .insured {
    font-weight: 500;
  }

  .risk-description {
    color: $color-grey-dark;
    font-size: 12px;
  }

  .inception {
    color: $color-grey-dark;
    font-size: 12px;
    margin-top: 5px;
  }

  .inception-current-year {
    color: $color-marmalade;
  }
}

.submission-summary-selector {
  background: $color-grey-light;
  border-radius: 5px;

  :hover {
    background: $color-grey;
    border-radius: 5px;
  }
}
