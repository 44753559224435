.top-nav {
  background: #fcfdfd;
  border-top: 1px solid #dce2eb;
  font-family: "IBM Plex Sans", sans-serif;
  box-shadow: 0px 4px 8px $shadow-color-light;

  &.sticky {
    position: sticky;
    top: 43px;
    z-index: $z-index-high;
    box-shadow: 0px 4px 8px $shadow-color-dark;
  }

  .container {
    display: flex;
    position: relative;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpoint-vertical-nav) {
      justify-content: center;
    }
  }

  .top-nav-tab {
    color: $color-nav-links;
    text-decoration: none;
    font-size: 15px;
    margin-right: 16px;
    cursor: pointer;
    transition: $transition;
    text-shadow: 0px 1px rgba(255, 255, 255, 0.81);
    position: relative;
    z-index: $z-index-high;
    font-weight: 500;
    padding: 10px;

    &:hover {
      color: $color-marmalade;
    }

    &.active-tab {
      color: $color-orange;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.top-nav.borderless {
  border-top: none;
}
