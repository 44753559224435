.flags {
  .good > .MuiFilledInput-root {
    background: $color-status-green;
  }

  .bad > .MuiFilledInput-root {
    background: $color-status-red;
  }
}

.component-error {
  box-shadow: 0px 0px 0 2px #e85f6c, 0px 0px 4px 2px $color-status-red;
}
