.component {
  padding: 16px;
  background: #fff;
  box-shadow: 0px 4px 8px $shadow-color-light;
  border-radius: 4px;

  .component-header-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .component-child-wrapper {
    margin-top: 24px;
  }

  &.compact {
    padding: 8px 16px 16px 16px;

    .component-child-wrapper {
      margin-top: 8px;
    }
  }

  .component-header {
    .component-subtitle {
      font-size: 14px;
      color: $color-grey;
      display: block;
      margin-top: 4px;
    }
  }

  p {
    color: $color-black;
  }
}

.component-stretch-vertically {
  height: 100%;
}

.component-title {
  font-size: 17px;
  font-weight: 600;
  color: $color-black;
  margin-bottom: 2px;
}
