.sheet-select-form {
  min-width: 100%;
}

.claims-sheet-select {
  min-width: 100%;
}

.claims-sheet-select-select > div {
  padding-left: 6px;
}

.MuiStepIcon-root.MuiStepIcon-active {
  text {
    fill: #fff;
    font-weight: bold;
  }
}

.claims-file-upload-dialog {
  .MuiTableRow-head .MuiTableCell-root {
    vertical-align: bottom;

    div.icon {
      transform: rotate(180deg);
      height: 24px;
      width: 24px;
      margin: -1px 0px 1px 0px;
    }
  }

  div.horizontal {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: auto;
    white-space: nowrap;
    overflow: visible;
  }
  div.vertical {
    display: block;
    justify-content: flex-start;
    transform: rotate(180deg);
    white-space: nowrap;
    overflow: visible;
  }

  div.vertical-writing {
    writing-mode: vertical-rl;
    align-items: center;
  }

  span.faded {
    color: $color-grey;
  }

  span.good {
    color: $color-status-green;
  }

  span.bad {
    color: $color-status-red;
  }

  span.horizontal-column-name {
    line-height: 24px;
  }

  div.horizontal-validation {
    display: flex;
  }
}

.sheet-preview-cell-active {
  background-color: $color-marmalade;
}
.sheet-preview-cell-border {
  background-color: $color-marmalade-light;
}
.sheet-preview-cell-content {
  background-color: $color-marmalade-lightest;
}
.sheet-preview-cell-type-int {
  text-align: right;
}
.sheet-preview-cell-type-float {
  text-align: right;
}
.sheet-preview-cell-type-date {
  text-align: right;
}
.sheet-preview-cell-type-datetime {
  text-align: right;
}
.sheet-preview-cell {
  color: black;
  cursor: pointer;
  &:hover {
    background: $color-marmalade;
  }
}
