.error-dialog {
  .errors {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .error-wrapper {
    border: 2px solid transparent;
    border-radius: 4px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
  }

  .error-content {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .title-and-date {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .title {
    font-weight: 700;
    font-size: 17px;
  }

  .message {
    font-size: 14px;
    line-height: 1.5;
  }

  .date {
    font-weight: 700;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
    align-self: flex-start;
    border-radius: 4px;
    padding: 2px 4px;
  }

  .fatal {
    border: 1px solid #f5beb7;

    .title {
      color: darken(#f5beb7, 45%);
    }

    .header {
      background-color: #f5beb7;
    }
  }

  .error {
    border: 1px solid #f4f2b7;

    .header {
      background-color: #f4f2b7;
    }
  }

  .info {
    color: #000;
  }

  .debug,
  .trace {
    color: #65676f;
  }
}
