h1 {
  font-size: 30px;
}

.print {
  font-size: 18px;
  max-width: min(100%, 1200px);
  margin: 0 auto;
  padding: 16px 24px 20px 24px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(36, 41, 48, 0.05);
  border-radius: 4px;
  font-family: "IBM Plex Sans", sans-serif;
}

.print .component {
  box-shadow: none;
}

.print-label {
  font-weight: bold;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 4px;
}

.print-header-section {
  display: flex;
}

.print-insured-header {
  margin-bottom: 24px;
}

.print-insured-header h1 {
  font-weight: bold;
  color: #000000;
}
.print-claims-table-and-freq-chart {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 16px;
  margin-bottom: 16px;
}

.print-claims-table-and-freq-chart .component {
  padding: 0 0 0 12px;
}

.print-risk-description {
  margin-top: 8px;
  display: inline-block;
}

.print-meta-detail {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  font-size: 14px;
}

.print-meta-detail .print-label {
  font-weight: bold;
}

.print-section {
  margin-bottom: 16px;
}

.print-section {
  margin-bottom: 48px;
}

.print-inline-flex {
  display: flex;
  gap: 16px;
  align-items: center;
}

.print-inline-flex-items {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.print-claims-charts {
  display: flex;
  margin-bottom: 32px;
  width: 100%;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .print-claims-charts {
    flex-direction: column;
  }
}

.print-claims-charts > div {
  flex-grow: 1;
  max-width: 100%;
  flex-basis: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  background: #fff;
}

.print-claims-charts .recharts-responsive-container {
  position: relative;
  height: 220px !important;
  width: 100% !important;
  z-index: 1;
}

.print-claims-charts .component {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  background: #fff;
}

.print-claims-table {
  position: relative;
  z-index: 2;
  margin-top: 32;
  font-size: 11px;
  background: #fff;
}

.print-claims-table .MuiDataGrid-root .MuiDataGrid-footer {
  display: none !important;
}

.MuiDataGrid-root {
  border: none;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  padding: 0 4px;
}

.MuiDataGrid-root .MuiDataGrid-footer {
  display: none;
}

.print-textarea .wmde-markdown {
  line-height: 1.4;
  font-weight: 400;
  font-size: 14px;
}

@page {
  size: letter;
}

@media print {
  .print {
    padding: 0px 0px 0px 24px;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
  }

  .print,
  .MuiInputBase-input,
  .MuiTableRow-head,
  .MuiTypography-subtitle1,
  .MuiTableCell-head {
    font-size: 11px;
  }

  .print > .container > .MuiGrid-root {
    padding: 0;
  }

  .print-notes {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .print-section {
    margin-bottom: 16px;
  }

  .page-break-after {
    page-break-after: always !important;
  }

  body {
    margin: 0;
  }

  .print-inline-flex {
    display: inline-block;
  }

  .print-inline-flex .print-label {
    margin-right: 4px;
  }

  .exposure-inputs.MuiTable-root .sticky-column-1 {
    min-width: 150px;
    width: 150px;
  }

  .exposure-inputs.MuiTable-root .sticky-column-2 {
    left: 100px;
    padding-right: 16px !important;
  }

  .MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense,
  .multiline-condensed-padding .MuiInputBase-multiline,
  .print-textarea {
    padding: 0;
  }

  .print-peer-comparison-table .MuiTableCell-root {
    font-size: 11px;
  }

  .exposure-inputs.MuiTable-root .MuiTableRow-head .MuiTableCell-root {
    color: rgba(0, 0, 0, 0.87);
    text-transform: none;
    font-weight: 500;
    line-height: 1.5rem;
  }

  .MuiTableCell-sizeSmall,
  .MuiTableCell-root {
    padding: 4px 2px !important;
  }

  .exposure-inputs.MuiTable-root .MuiTableCell-body {
    min-width: 100px;
  }

  .no-print,
  .active-submission-wrapper,
  .navbar-wrapper,
  .footer-wrapper,
  .MuiAppBar-root,
  .MuiTooltip-popper {
    display: none !important;
  }

  .component {
    box-shadow: none;
    padding: 0;
  }

  .dashboard {
    box-shadow: none;
  }
}
