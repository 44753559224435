#react-doc-viewer {
  #header-bar {
    display: none;
  }
}

.react-pdf__Page__textContent.textLayer {
  display: none;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}
