@import "variables";

.cell-condensed-padding {
  padding: 10px 2px;

  svg {
    margin-top: 5px;
  }
}

.cell-condensed-padding-md {
  padding: 14px 4px;
}

.table-cell-bad-deal {
  background: $color-status-red;
  color: $color-status-red-text;
  border-radius: 6px;
  padding: 3px 5px;
}

.table-cell-good-deal {
  background: $color-status-green;
  color: $color-status-green-text;
  border-radius: 6px;
  padding: 3px 5px;
}

.table-row-inactive {
  background: $color-grey-very-light;
}

.table-row-error {
  background: $color-status-red;
}

.condensed-table {
  .MuiTableRow-root .MuiTableCell-sizeSmall {
    padding: 10px 4px;

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }
  }
}

.table-with-total-row {
  .MuiTableBody-root .MuiTableRow-root:nth-last-child(2) .MuiTableCell-root {
    border-bottom: 1px solid $color-grey-dark;
  }

  .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
    font-weight: bold;
  }
}

.table-with-clickable-rows {
  .MuiTableRow-root:hover {
    cursor: pointer;
  }
}

.data-grid {
  .material-row {
    &:hover {
      background-color: $color-grey-light !important;
    }

    &.selected {
      background-color: #dbdce5 !important;
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }
  .material-cell:focus,
  .material-col-cell:focus,
  .material-cell:active,
  .material-col-cell:active,
  .MuiDataGrid-colCell:focus,
  .MuiDataGrid-colCell:active,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:active {
    outline: none !important;
  }

  .right-aligned-cell {
    padding-right: 36px;
  }
}

@media print {
  .table-cell-bad-deal {
    color: black;
  }

  .table-cell-good-deal {
    color: black;
  }
}
