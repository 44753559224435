.inline-search {
  max-width: 18rem;
  height: 36px;
  padding: 0 7px;
  border-radius: 18px;
  background: $color-grey-very-light;

  &:hover:not(.active) {
    filter: brightness(0.95);
  }

  .search-icon {
    fill: $color-grey;
    font-size: 22px;
  }

  input {
    flex-grow: 1;
    border: none;
    outline: none;
    background: $color-grey-very-light;
  }

  ::placeholder {
    color: $color-grey;
  }

  .clear-icon {
    fill: $color-input-label;
    cursor: pointer;
    transition: 150ms;
  }

  &.active {
    .search-icon {
      fill: $color-marmalade;
    }
  }
}

.inline-search.no-background {
  background: transparent;
  max-width: unset;

  &:hover:not(.active) {
    filter: unset;
  }

  input {
    background: none;
  }
}

.inline-search.full-width {
  max-width: unset;
  width: 100%;
}
