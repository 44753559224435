.claims-v2-mapping-table {
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  thead tr th,
  tbody tr td {
    padding: 2px 20px;
    margin: 0;
  }
  thead tr th {
    text-align: center;
  }
  thead tr:nth-child(1) th:nth-child(1),
  thead tr:nth-child(2) th:nth-child(2),
  tbody tr td:nth-child(2) {
    border-right: 1px solid $color-marmalade-dark;
  }
  tbody tr td {
    border: 0;
  }
  tbody tr:nth-child(odd) {
    background-color: $color-marmalade-lightest;
  }
}
