.mapper {
  .MuiTableBody-root {
    .MuiTableRow-root {
      &:hover {
        background-color: #fffee8;
      }
    }
  }

  .highlighted {
    background-color: #fffee8;
  }
}
