.dashboard {
  position: relative;
  box-shadow: 0px 4px 8px $shadow-color-light;
  flex-grow: 1;

  .dashboard-content {
    padding: 24px 12px 60px 12px;
  }

  &.variant-pending {
    background: $color-marmalade-lighter;
  }

  &.variant-warning {
    background: $color-status-red;
  }

  &.variant-readonly {
    background: $color-grey;
  }

  &.admin-env-prod {
    background: $color-status-red;
  }

  &.admin-env-test {
    background: $color-status-yellow;
  }

  &.admin-env-dev {
    background: $color-status-green;
  }
}
