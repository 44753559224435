@import "@aws-amplify/ui-react/styles.css";

:root {
  --amplify-colors-primary-10: #{$color-marmalade-lightest};
  --amplify-colors-primary-20: #{$color-marmalade-lightest};
  --amplify-colors-primary-60: #{$color-marmalade};
  --amplify-colors-primary-80: #{$color-marmalade};
  --amplify-colors-primary-90: #{$color-marmalade};
  --amplify-colors-primary-100: #{$color-marmalade};
  --amplify-components-tabs-panel-padding-block: 0;
}

[data-amplify-authenticator] {
  [data-amplify-router] {
    border-radius: 0.375rem;
  }
}
