.exposure-inputs.MuiTable-root {
  width: auto;

  td,
  th {
    padding: 6px;
  }

  .MuiTableRow-head {
    text-transform: capitalize;

    .MuiTableCell-root {
      color: $color-grey;
    }
  }

  .MuiTableCell-body {
    min-width: 170px;
  }

  .MuiTypography-subtitle2 {
    color: $color-grey;
  }

  .good {
    background-color: $color-status-green;
  }

  .bad {
    background-color: $color-status-red;
  }

  .name-column {
    left: 0;
    position: sticky;
    z-index: 1;
    background: #fff;
    border: none;
    min-width: 180px;
    max-width: 180px;
    width: 180px;
    box-shadow: 0px 1px 0px #fff;

    .MuiTableCell-body {
      border: none;
    }
  }

  .notes-column {
    left: 255px;
    position: sticky;
    z-index: 1;
    background: #fff;
    border: none;
    box-shadow: 0px 1px 0px #fff;

    .MuiTableCell-body {
      border: none;
    }
  }

  .units-column {
    left: 180px;
    position: sticky;
    z-index: 1;
    background: #fff;
    border: none;
    min-width: 75px;
    max-width: 75px;
    width: 75px;
    box-shadow: 0px 1px 0px #fff;

    .MuiTableCell-body {
      border: none;
    }
  }

  .eye-column {
    left: 0;
    position: sticky;
    z-index: 1;
    background: #fff;
    border: none;
    min-width: 20px;
    width: 20px;
    box-shadow: 0px 1px 0px #fff;

    .MuiTableCell-body {
      border: none;
    }
  }

  .name-header {
    left: 0;
    position: sticky;
    z-index: 3;
    background: #fff;
    border: none;
    min-width: 180px;
    width: 180px;
    box-shadow: 0px 1px 0px #fff;

    .MuiTableCell-body {
      border: none;
    }
  }

  .unit-header {
    left: 180px;
    position: sticky;
    z-index: 3;
    background: #fff;
    border: none;
    min-width: 75px;
    width: 75px;
    box-shadow: 0px 1px 0px #fff;

    .MuiTableCell-body {
      border: none;
    }
  }

  .eye-header {
    left: 0px;
    position: sticky;
    z-index: 2;
    background: #fff;
    border: none;
    min-width: 20px;
    width: 20px;
    box-shadow: 0px 1px 0px #fff;

    .MuiTableCell-body {
      border: none;
    }
  }

  .years-header {
    left: 0;
    position: sticky;
    background: #fff;
    border: none;
    min-width: 20px;
    width: 20px;
    box-shadow: 0px 1px 0px #fff;

    .MuiTableCell-body {
      border: none;
    }
  }

  .arrow-column {
    text-align: center;
    min-width: 40px;
    width: 40px;
    padding: 0px;

    .MuiTableCell-body {
      border: none;
    }
  }

  .trending {
    margin: auto;
    border-collapse: collapse;

    td {
      padding: 0;
      margin: 0;
    }
  }

  .arrowIcon {
    line-height: 0px;
  }

  .up {
    color: $color-green;
  }

  .down {
    color: $color-red;
  }

  .arrow {
    transform: scaleX(-1);
  }
}
