.tags {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 4px;
  margin-bottom: 12px;

  .tag {
    background: $color-grey-very-light;
    padding: 8px;
    border-radius: 4px;
    font-size: 18px;
    margin: 4px;
    color: $color-black;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      background: $color-status-green;
    }

    &:active {
      transform: scale(0.95);
    }
  }
}
