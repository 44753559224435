.navbar-wrapper {
  background: #fff;

  &.sticky {
    position: sticky;
    top: 0;
    z-index: $z-index-high;
  }

  .navbar {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $breakpoint-vertical-nav) {
      display: block;
    }
  }

  .navbar.container.full-width {
    max-width: unset;
  }

  .nav-left {
    display: flex;
    flex-wrap: wrap;
    padding: 6px;

    @media screen and (max-width: $breakpoint-vertical-nav) {
      justify-content: center;
    }

    .nav-link {
      color: $color-nav-links;
      font-family: $font;
      text-decoration: none;
      $transition: $transition-fast;
      padding: 5px 7px;
      margin-right: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 15px;
      line-height: 1.375;
      border-radius: 4px;

      @media screen and (max-width: $breakpoint-mobile-nav) {
        margin: 0 16px;
      }

      &:last-of-type {
        @media screen and (max-width: $breakpoint-mobile-nav) {
          margin-right: 16px;
        }
      }

      &:hover {
        cursor: pointer;
        color: $color-black;
        background-color: $color-orange-light;

        .MuiSvgIcon-root {
          color: $color-orange;
        }
      }

      &.active-section {
        font-weight: 500;
        color: $color-black;
        background-color: $color-orange-light;
        box-shadow: inset 0 0 4px #dc804c33;

        .MuiSvgIcon-root {
          color: $color-orange;
        }
      }

      .MuiSvgIcon-root {
        margin-right: 5px;
        font-size: 21px;
        transition: $transition;
      }
    }
  }

  .nav-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 12px;

    @media screen and (max-width: $breakpoint-vertical-nav) {
      justify-content: center;
      padding: 8px 12px;
    }

    .link {
      color: $color-grey;
      user-select: none;
      transition: $transition;
      margin: 0 4px;
      font-weight: 500;
      font-family: $font;
      display: flex;
      align-items: center;
      font-size: 14px;

      @media screen and (max-width: $breakpoint-mobile-nav) {
        margin: 8px 12px;
      }

      &:hover {
        color: $color-black;
        cursor: pointer;
      }
    }
  }

  .nav-button {
    background: transparent;
    color: $color-orange;
    border: 1px solid $color-orange;
    text-transform: none;
    margin-left: 12px;

    &:hover {
      background: $color-orange;
      color: #fff;
    }

    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
