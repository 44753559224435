.endorsement-selected.MuiLoadingButton-root {
  width: 100%;
  border: 2px solid $color-marmalade;

  &:hover {
    background: $color-marmalade-light;
  }
}
.endorsement-not-selected.MuiLoadingButton-root {
  width: 100%;
  color: $color-orange;
  background: white;
  border: 2px solid $color-marmalade;

  &:hover {
    background: $color-marmalade-lighter;
  }
}
