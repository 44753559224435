.tower-diagram {
  $border-color: rgba(0, 0, 0, 0.12);

  max-width: 52rem;
  border: 1px solid $border-color;
  border-radius: 0.625rem;

  > :first-child {
    border-top-left-radius: 0.5625rem;
    border-top-right-radius: 0.5625rem;
  }

  > :last-child {
    border-bottom-left-radius: 0.5625rem;
    border-bottom-right-radius: 0.5625rem;
  }

  .layer {
    padding: 0.75rem 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    &.dim {
      background: rgba(0, 0, 0, 0.04);
      color: gray;
    }

    &.highlight {
      background: #fff1e9;
    }

    .MuiTypography-overline,
    .MuiTypography-subtitle2 {
      line-height: 1.5;
    }
  }
}
