.footer-wrapper {
  background: $color-footer;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
  }

  .meta-data {
    color: $color-grey;
    font-size: 11px;
    margin-left: 8px;

    .label {
      font-weight: bold;
      display: inline-block;
      margin-right: 2px;
      font-size: 11px;
      margin-bottom: 0;
    }
  }

  .user-preferences-link {
    color: $color-grey;

    a {
      color: $color-grey;
    }
  }

  .sign-out {
    min-width: 60px;
    color: $color-grey;
    font-size: 11px;
  }

  .sign-out:hover {
    background: transparent;
    color: $color-grey-dark;
    font-size: 11px;
  }

  .meta-data-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
