.search-dialog {
  .search-form {
    padding: 0 8px 8px 8px;

    .input-and-checkbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      top: 0;
      padding: 16px 0;
      background: $color-grey-very-light;
      z-index: 99;
    }

    .search-input {
      border-radius: 6px;
      background-color: $color-white;
      padding: 10px 16px;
      flex-grow: 1;
      margin-right: 16px;
      transition: $transition;
      min-width: 350px;

      .MuiSvgIcon-root {
        color: $color-grey-dark;
      }

      input {
        font-size: 22px;
        color: $color-black;
      }

      & > *,
      & > *:focus {
        &:before,
        &:after {
          content: none;
        }
      }

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0px 4px 8px $shadow-color-light;

        .search-icon {
          color: $color-marmalade;
        }
      }
    }

    .MuiFormControlLabel-root {
      min-width: 100px;
    }

    .results {
      width: 40rem;
      margin: 0 auto;

      @media screen and (max-width: $breakpoint-vertical-search-results) {
        width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 8px;
      }
    }

    .legacy {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      width: unset;
      margin: unset;
    }

    .view-more {
      color: $color-grey;
      text-align: center;
      display: block;
      font-weight: 500;
      font-size: 11px;
      margin: 16px 0;
      transition: $transition;

      &:hover {
        cursor: pointer;
        filter: brightness(0.8);
      }
    }

    .search-result {
      margin-bottom: 6px;
      padding: 10px 14px;
      border-radius: 4px;
      background: #fbfbfc;
      font-family: $font !important;
      transition: $transition;
      cursor: pointer;

      header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }

      h5 {
        font-size: 14px;
        font-weight: 500;
      }

      .inception {
        flex-shrink: 0;
        font-size: 13px;
        font-weight: 500;
        color: $color-grey;

        .highlight {
          color: $color-marmalade;
        }
      }

      p {
        font-size: smaller;
        color: $color-grey;
        margin: 0;
      }

      &:hover {
        box-shadow: 0px 4px 16px $shadow-color-dark;
      }
    }
  }
}

.search-text-field {
  position: relative;

  .result-list {
    max-height: 240px;
    overflow-y: auto;

    li {
      font-size: 0.9rem;
    }
  }
}
