.options {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;

  .option {
    background: $color-grey-very-light;
    padding: 8px 14px;
    border-radius: 8px;
    margin: 0 10px 10px 0;
    color: $color-black;
    user-select: none;
    transition: 100ms;

    &.bright {
      background: white;
    }

    &.round {
      font-size: 15px;
      line-height: 15px;
      padding-top: 6px;
      border-radius: 20px;
      margin: 0 9px 9px 0;
    }

    &:hover {
      cursor: pointer;
      background: $color-grey-medium;
    }

    &:active {
      transform: scale(0.98);
    }

    &.selected {
      background: $color-marmalade;
      color: $color-white;

      &:hover {
        background: $color-marmalade-dark;
      }
    }

    &.faded {
      background: $color-marmalade-light;
      color: $color-white;

      &:hover {
        background: $color-marmalade-dark;
      }
    }

    &.severity {
      display: flex;
      align-items: center;
      padding: 6px 0 6px 12px;

      &:hover {
        box-shadow: 0 0 0 2px inset $color-grey-very-light;
      }

      &:after {
        content: "N/A";
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 2.375rem;
        height: calc(100% + 12px);
        margin-left: 0.5rem;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        font-size: 0.675rem;
        font-weight: bold;
        color: $color-white;
        background: $color-grey;
        transition: 100ms;
      }

      &.low {
        box-shadow: 0 0 0 2px inset deepskyblue;

        &::after {
          content: "Low";
          background: deepskyblue;
          color: white;
        }
      }

      &.medium {
        box-shadow: 0 0 0 2px inset gold;

        &::after {
          content: "Med";
          background: gold;
          color: #845e00;
        }
      }

      &.high {
        box-shadow: 0 0 0 2px inset orange;

        &::after {
          content: "High";
          background: orange;
          color: #6f3c07;
        }
      }

      &.maximum {
        box-shadow: 0 0 0 2px inset red;

        &::after {
          content: "Max";
          background: red;
        }
      }
    }

    &.required {
      border: 2px solid $color-marmalade;

      &:hover {
        background: $color-grey-very-light;
        cursor: default;
      }
    }
  }
}
