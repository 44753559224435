.person-summary-text {
  color: $color-black;
  white-space: break-spaces;

  .name {
    font-weight: 500;
  }

  .company {
    color: $color-grey-dark;
    font-size: 12px;
  }

  .email {
    color: $color-grey-dark;
    font-size: 12px;
    margin-top: 10px;
  }
}

.person-summary {
  padding: 10px;
}

.person-summary-selector {
  background: $color-grey-light;
  border-radius: 5px;

  :hover {
    background: $color-grey;
    border-radius: 5px;
  }

  .person-summary-text {
    padding: 10px;
  }
}
