.print {
  .judgement {
    .value {
      padding: 3px 5px;
      border-radius: 5px;
    }
    .good {
      background: $color-status-green;
      color: $color-status-green-text;
    }

    .bad {
      background: $color-status-red;
      color: $color-status-red-text;
    }
  }
}

@media print {
  body {
    background: none;
  }

  .print {
    .judgement {
      .good {
        box-shadow: 0 0 0 100px inset $color-status-green;
        color: $color-status-green-text;
      }

      .bad {
        box-shadow: 0 0 0 100px inset $color-status-red;
        color: $color-status-red-text;
      }
    }
  }
}
