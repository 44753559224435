.select-button {
  button {
    color: black;
    background: white;

    &.outlined {
      padding: 0.375rem 0.25rem 0.375rem 0.5rem;
    }

    &:hover {
      background: white;
    }
  }

  .expand-icon {
    margin-left: 0.125rem;
    color: #9297a2;
  }

  &.compact {
    button {
      padding: 0.125rem 0.0625rem 0.125rem 0.4375rem;
    }
  }
}
