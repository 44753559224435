.peers-table {
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 100%;

  .MuiDialogActions-root {
    padding: 16px;
  }

  .MuiTypography-h6 {
    text-align: center;
    margin-top: 32px;
  }

  .input-and-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .search-input {
    border-radius: 4px;
    background-color: $color-grey-very-light;
    padding: 10px 16px;
    flex-grow: 1;
    margin-right: 16px;
    transition: $transition;
    min-width: 350px;

    .MuiSvgIcon-root {
      color: $color-grey-dark;
    }

    input {
      font-size: 22px;
      color: $color-black;
    }

    & > *,
    & > *:focus {
      &:before,
      &:after {
        content: none;
      }
    }

    &:hover,
    &:focus,
    &:active {
      filter: brightness(0.95);
    }
  }

  .MuiFormControlLabel-root {
    min-width: 100px;
  }

  .results {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;

    @media screen and (max-width: $breakpoint-vertical-search-results) {
      grid-template-columns: 1fr;
      grid-gap: 8px;
    }
  }

  .list-of-submissions-table {
    border-spacing: 0 5px !important;
    border-collapse: separate !important;
  }

  .view-more {
    color: $color-grey;
    text-align: center;
    display: block;
    font-weight: 500;
    font-size: 11px;
    margin: 16px 0;
    transition: $transition;

    &:hover {
      cursor: pointer;
      filter: brightness(0.8);
    }
  }

  .search-row {
    transition: $transition;
    background: #fbfbfc;
    cursor: pointer;

    span {
      font-size: 15px;
      color: $color-black;
      font-family: $font !important;
    }

    .search-result-insured {
      span {
        font-weight: 500;
      }
    }

    .search-result-risk-description,
    .search-result-inception {
      text-align: right;

      span {
        color: $color-grey;
      }
    }

    &:hover {
      filter: brightness(0.95);
    }

    .MuiTableCell-root {
      border: none;
      padding: 8px;
    }

    .MuiTableCell-root:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .MuiTableCell-root:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.search-text-field {
  position: relative;

  .result-list {
    max-height: 240px;
    overflow-y: auto;

    li {
      font-size: 0.9rem;
    }
  }
}
