.MuiFilledInput-root {
  background: $color-grey-very-light;

  &:hover,
  &.Mui-focused {
    background: #dfe5ea;
  }
}

.MuiFilledInput-underline {
  border-radius: 4px;
  transition: $transition;

  &:hover:before {
    border-bottom: none;
  }

  &:before,
  &:after {
    border-bottom: none;
  }
}

.MuiFormLabel-root {
  font-weight: 600;
  color: $color-input-label;

  &.Mui-focused {
    color: $color-black;
  }
}

.MuiSelect-select {
  white-space: unset !important;
}

.MuiSelect-select:focus {
  background-color: rgba(0, 0, 0, 0);
}

.MuiFilledInput-root.Mui-disabled {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);

  &::before {
    border-bottom-style: none;
  }
}

.multiline-condensed-padding {
  .MuiInputBase-multiline {
    padding-top: 10px;
    padding-bottom: 11px;
  }
}

.MuiInput-underline:after {
  border-bottom: 2px solid transparent;
}

.input-label {
  font-size: 12px;
}

.MuiFormHelperText-root {
  color: $color-grey;
}

.MuiSlider-markLabel {
  font-weight: bold;
}

.MuiDialogTitle-root,
.MuiDialogContent-root,
.MuiDialogActions-root {
  background: $color-grey-very-light;
}
